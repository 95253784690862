import { makeVar } from '@apollo/client'
import { getI18n } from 'react-i18next'

import { infoToast } from '@/utils/toast'
import { authTokenStore } from '@/service/auth/store'
import urlPathWithParams from '@/utils/browser/url-path-with-params'

export const isSigninModalOpenStore = makeVar(false)

export const redirectUrlAfterSigninStore = makeVar('')

export const openSigninModal = (url = urlPathWithParams()) => {
  isSigninModalOpenStore(true)
  redirectUrlAfterSigninStore(url)
}

/**
 * 자체적으로 토큰 체크와 토스트 기능이 내장되어 있는 signin modal 도우미 함수
 */
export const openSigninModalWithToast = (
  callback: () => void = () => null,
  url = urlPathWithParams(),
) => {
  if (authTokenStore().token) {
    callback()
  } else {
    openSigninModal(url)
    const lang = getI18n().language
    infoToast(getI18n().getResource(lang, 'common', 'withSignIn'))
  }
}

export const closeSigninModal = () => {
  isSigninModalOpenStore(false)
  redirectUrlAfterSigninStore('')
}
