import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AssetInfoInput = {
  assetName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  cryptoCurrencyId?: InputMaybe<Scalars['ID']>;
  exchange?: InputMaybe<Scalars['String']>;
  ticker?: InputMaybe<Scalars['String']>;
  type: AssetType;
};

export enum AssetType {
  Company = 'COMPANY',
  CryptoCurrency = 'CRYPTO_CURRENCY'
}

export type Company = {
  __typename?: 'Company';
  country: Scalars['String'];
  exchange: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ticker: Scalars['String'];
};

export type CrawlingNewsKeyword = {
  __typename?: 'CrawlingNewsKeyword';
  id: Scalars['ID'];
  keyword: Scalars['String'];
};

export enum CrawlingNewsKeywordSortType {
  CrawlingCount = 'CRAWLING_COUNT',
  UpdatedAt = 'UPDATED_AT',
  UserRegisteredCount = 'USER_REGISTERED_COUNT'
}

export type CryptoCurrency = {
  __typename?: 'CryptoCurrency';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
};

export type Language = {
  __typename?: 'Language';
  en?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCrawlingNewsKeyword?: Maybe<CrawlingNewsKeyword>;
  removeCrawlingNewsKeyword?: Maybe<CrawlingNewsKeyword>;
  toggleRecommend?: Maybe<News>;
};


export type MutationAddCrawlingNewsKeywordArgs = {
  keyword: Scalars['String'];
};


export type MutationRemoveCrawlingNewsKeywordArgs = {
  keyword: Scalars['String'];
};


export type MutationToggleRecommendArgs = {
  newsId: Scalars['ID'];
};

export type News = {
  __typename?: 'News';
  companies?: Maybe<Array<Company>>;
  companyIds?: Maybe<Array<Scalars['ID']>>;
  countryCode?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  crawlingNewsKeywords?: Maybe<Array<CrawlingNewsKeyword>>;
  cryptoCurrencies?: Maybe<Array<CryptoCurrency>>;
  cryptoCurrencyIds?: Maybe<Array<Scalars['ID']>>;
  description: Language;
  dynamicLinkUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  locale: Scalars['String'];
  ogInfo?: Maybe<OgInfo>;
  originalLink: Scalars['String'];
  pubDate: Scalars['String'];
  publisher: Language;
  recommendedCount?: Maybe<Scalars['Int']>;
  recommendedUserIds?: Maybe<Array<Scalars['ID']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Language;
  viewCount?: Maybe<Scalars['Int']>;
};

export type NewsCountry = {
  __typename?: 'NewsCountry';
  countryCode: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Language;
};

export type OgInfo = {
  __typename?: 'OgInfo';
  articleAuthor?: Maybe<Scalars['String']>;
  articlePublishedTime?: Maybe<Scalars['String']>;
  articleSection?: Maybe<Scalars['String']>;
  charset?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImageUrl?: Maybe<Scalars['String']>;
  ogLocale?: Maybe<Scalars['String']>;
  ogTitle?: Maybe<Scalars['String']>;
};

export enum PlatformType {
  App = 'APP',
  MobileWeb = 'MOBILE_WEB',
  PcWeb = 'PC_WEB'
}

export type PopularSearchedNewsKeyword = {
  __typename?: 'PopularSearchedNewsKeyword';
  id: Scalars['ID'];
  keyword: Scalars['String'];
  rankChangeStatus: RankChangeStatus;
};

export type Query = {
  __typename?: 'Query';
  assetNews?: Maybe<Array<News>>;
  businessNews?: Maybe<Array<News>>;
  crawlingNewsKeywordNotificationStatus?: Maybe<Scalars['Boolean']>;
  holdingAssetNews?: Maybe<Array<News>>;
  myCrawlingNewsKeywords?: Maybe<Array<UserCrawlingNewsKeyword>>;
  newsDetail?: Maybe<News>;
  popularSearchedNewsKeywords?: Maybe<Array<PopularSearchedNewsKeyword>>;
  recommendedCrawlingNewsKeywords?: Maybe<Array<CrawlingNewsKeyword>>;
  recommendedSearchedNewsKeywords?: Maybe<Array<SearchedNewsKeyword>>;
  searchNewsByKeyword?: Maybe<Array<News>>;
  sortedNews?: Maybe<Array<News>>;
  stockNews?: Maybe<Array<News>>;
  supportedCountryForNews?: Maybe<Array<NewsCountry>>;
};


export type QueryAssetNewsArgs = {
  assetInfos: Array<AssetInfoInput>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessNewsArgs = {
  country?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCrawlingNewsKeywordNotificationStatusArgs = {
  keyword: Scalars['String'];
};


export type QueryHoldingAssetNewsArgs = {
  country?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryNewsDetailArgs = {
  newsId: Scalars['ID'];
};


export type QueryPopularSearchedNewsKeywordsArgs = {
  language: Scalars['String'];
  limit: Scalars['Int'];
};


export type QueryRecommendedCrawlingNewsKeywordsArgs = {
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sortType: CrawlingNewsKeywordSortType;
};


export type QueryRecommendedSearchedNewsKeywordsArgs = {
  language: Scalars['String'];
  limit: Scalars['Int'];
};


export type QuerySearchNewsByKeywordArgs = {
  keyword: Scalars['String'];
  language: Scalars['String'];
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  platform: PlatformType;
};


export type QuerySortedNewsArgs = {
  country?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sortType: SortType;
};


export type QueryStockNewsArgs = {
  country?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
};


export type QuerySupportedCountryForNewsArgs = {
  newsType?: InputMaybe<NewsType>;
};

export type SearchedNewsKeyword = {
  __typename?: 'SearchedNewsKeyword';
  id: Scalars['ID'];
  keyword: Scalars['String'];
};

export type UserCrawlingNewsKeyword = {
  __typename?: 'UserCrawlingNewsKeyword';
  id: Scalars['ID'];
  isUserReceivingKeywordNotification: Scalars['Boolean'];
  keyword: Scalars['String'];
};

export enum NewsType {
  AssetNews = 'ASSET_NEWS',
  TodaysPick = 'TODAYS_PICK'
}

export enum RankChangeStatus {
  Down = 'DOWN',
  New = 'NEW',
  Same = 'SAME',
  Up = 'UP'
}

export enum SortType {
  RecommendedCount = 'RECOMMENDED_COUNT',
  ViewCount = 'VIEW_COUNT'
}

export type NewsFieldsFragment = { __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null };

export type NewsDetailFieldsFragment = { __typename?: 'News', id: string, link: string, locale: string, recommendedUserIds?: Array<string> | null, recommendedCount?: number | null, dynamicLinkUrl?: string | null, countryCodes?: Array<string> | null, language?: string | null, title: { __typename?: 'Language', ko?: string | null, en?: string | null } };

export type NewsCountryFieldsFragment = { __typename?: 'NewsCountry', id: string, countryCode: string, imageUrl: string, name: { __typename?: 'Language', en?: string | null, ko?: string | null } };

export type PopularSearchedNewsKeywordFieldsFragment = { __typename?: 'PopularSearchedNewsKeyword', id: string, keyword: string, rankChangeStatus: RankChangeStatus };

export type UserCrawlingNewsKeywordFieldsFragment = { __typename?: 'UserCrawlingNewsKeyword', id: string, keyword: string, isUserReceivingKeywordNotification: boolean };

export type ToggleRecommendMutationVariables = Exact<{
  newsId: Scalars['ID'];
}>;


export type ToggleRecommendMutation = { __typename?: 'Mutation', toggleRecommend?: { __typename?: 'News', recommendedUserIds?: Array<string> | null, recommendedCount?: number | null } | null };

export type AddCrawlingNewsKeywordMutationVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type AddCrawlingNewsKeywordMutation = { __typename?: 'Mutation', addCrawlingNewsKeyword?: { __typename?: 'CrawlingNewsKeyword', id: string, keyword: string } | null };

export type RemoveCrawlingNewsKeywordMutationVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type RemoveCrawlingNewsKeywordMutation = { __typename?: 'Mutation', removeCrawlingNewsKeyword?: { __typename?: 'CrawlingNewsKeyword', id: string, keyword: string } | null };

export type AssetNewsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  assetInfos: Array<AssetInfoInput> | AssetInfoInput;
}>;


export type AssetNewsQuery = { __typename?: 'Query', assetNews?: Array<{ __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null }> | null };

export type BusinessNewsQueryVariables = Exact<{
  country: Scalars['String'];
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;


export type BusinessNewsQuery = { __typename?: 'Query', businessNews?: Array<{ __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null }> | null };

export type HoldingAssetNewsQueryVariables = Exact<{
  country: Scalars['String'];
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;


export type HoldingAssetNewsQuery = { __typename?: 'Query', holdingAssetNews?: Array<{ __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null }> | null };

export type SortedNewsQueryVariables = Exact<{
  sortType: SortType;
  language: Scalars['String'];
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
}>;


export type SortedNewsQuery = { __typename?: 'Query', sortedNews?: Array<{ __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null }> | null };

export type SupportedCountryForNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportedCountryForNewsQuery = { __typename?: 'Query', supportedCountryForNews?: Array<{ __typename?: 'NewsCountry', id: string, countryCode: string, imageUrl: string, name: { __typename?: 'Language', en?: string | null, ko?: string | null } }> | null };

export type NewsDetailQueryVariables = Exact<{
  newsId: Scalars['ID'];
}>;


export type NewsDetailQuery = { __typename?: 'Query', newsDetail?: { __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null } | null };

export type SearchNewsByKeywordQueryVariables = Exact<{
  language: Scalars['String'];
  platform: PlatformType;
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
}>;


export type SearchNewsByKeywordQuery = { __typename?: 'Query', searchNewsByKeyword?: Array<{ __typename?: 'News', id: string, link: string, locale: string, pubDate: string, thumbnailUrl?: string | null, companyIds?: Array<string> | null, dynamicLinkUrl?: string | null, recommendedCount?: number | null, recommendedUserIds?: Array<string> | null, description: { __typename?: 'Language', ko?: string | null, en?: string | null }, publisher: { __typename?: 'Language', ko?: string | null, en?: string | null }, title: { __typename?: 'Language', ko?: string | null, en?: string | null }, cryptoCurrencies?: Array<{ __typename?: 'CryptoCurrency', id: string, name?: string | null, symbol: string }> | null }> | null };

export type RecommendedSearchedNewsKeywordsQueryVariables = Exact<{
  language: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type RecommendedSearchedNewsKeywordsQuery = { __typename?: 'Query', recommendedSearchedNewsKeywords?: Array<{ __typename?: 'SearchedNewsKeyword', id: string, keyword: string }> | null };

export type PopularSearchedNewsKeywordsQueryVariables = Exact<{
  language: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type PopularSearchedNewsKeywordsQuery = { __typename?: 'Query', popularSearchedNewsKeywords?: Array<{ __typename?: 'PopularSearchedNewsKeyword', id: string, keyword: string, rankChangeStatus: RankChangeStatus }> | null };

export type MyCrawlingNewsKeywordsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCrawlingNewsKeywordsQuery = { __typename?: 'Query', myCrawlingNewsKeywords?: Array<{ __typename?: 'UserCrawlingNewsKeyword', id: string, keyword: string, isUserReceivingKeywordNotification: boolean }> | null };

export type CrawlingNewsKeywordNotificationStatusQueryVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type CrawlingNewsKeywordNotificationStatusQuery = { __typename?: 'Query', crawlingNewsKeywordNotificationStatus?: boolean | null };

export const NewsFieldsFragmentDoc = gql`
    fragment NewsFields on News {
  id
  description {
    ko
    en
  }
  link
  locale
  pubDate
  publisher {
    ko
    en
  }
  thumbnailUrl
  title {
    ko
    en
  }
  companyIds
  cryptoCurrencies {
    id
    name
    symbol
  }
  dynamicLinkUrl
  recommendedCount
  recommendedUserIds
}
    `;
export const NewsDetailFieldsFragmentDoc = gql`
    fragment NewsDetailFields on News {
  id
  link
  locale
  recommendedUserIds
  recommendedCount
  dynamicLinkUrl
  countryCodes
  language
  title {
    ko
    en
  }
}
    `;
export const NewsCountryFieldsFragmentDoc = gql`
    fragment NewsCountryFields on NewsCountry {
  id
  name {
    en
    ko
  }
  countryCode
  imageUrl
}
    `;
export const PopularSearchedNewsKeywordFieldsFragmentDoc = gql`
    fragment PopularSearchedNewsKeywordFields on PopularSearchedNewsKeyword {
  id
  keyword
  rankChangeStatus
}
    `;
export const UserCrawlingNewsKeywordFieldsFragmentDoc = gql`
    fragment UserCrawlingNewsKeywordFields on UserCrawlingNewsKeyword {
  id
  keyword
  isUserReceivingKeywordNotification
}
    `;
export const ToggleRecommendDocument = gql`
    mutation ToggleRecommend($newsId: ID!) {
  toggleRecommend(newsId: $newsId) {
    recommendedUserIds
    recommendedCount
  }
}
    `;
export type ToggleRecommendMutationFn = Apollo.MutationFunction<ToggleRecommendMutation, ToggleRecommendMutationVariables>;

/**
 * __useToggleRecommendMutation__
 *
 * To run a mutation, you first call `useToggleRecommendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleRecommendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleRecommendMutation, { data, loading, error }] = useToggleRecommendMutation({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useToggleRecommendMutation(baseOptions?: Apollo.MutationHookOptions<ToggleRecommendMutation, ToggleRecommendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleRecommendMutation, ToggleRecommendMutationVariables>(ToggleRecommendDocument, options);
      }
export type ToggleRecommendMutationHookResult = ReturnType<typeof useToggleRecommendMutation>;
export type ToggleRecommendMutationResult = Apollo.MutationResult<ToggleRecommendMutation>;
export type ToggleRecommendMutationOptions = Apollo.BaseMutationOptions<ToggleRecommendMutation, ToggleRecommendMutationVariables>;
export const AddCrawlingNewsKeywordDocument = gql`
    mutation AddCrawlingNewsKeyword($keyword: String!) {
  addCrawlingNewsKeyword(keyword: $keyword) {
    id
    keyword
  }
}
    `;
export type AddCrawlingNewsKeywordMutationFn = Apollo.MutationFunction<AddCrawlingNewsKeywordMutation, AddCrawlingNewsKeywordMutationVariables>;

/**
 * __useAddCrawlingNewsKeywordMutation__
 *
 * To run a mutation, you first call `useAddCrawlingNewsKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrawlingNewsKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrawlingNewsKeywordMutation, { data, loading, error }] = useAddCrawlingNewsKeywordMutation({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useAddCrawlingNewsKeywordMutation(baseOptions?: Apollo.MutationHookOptions<AddCrawlingNewsKeywordMutation, AddCrawlingNewsKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCrawlingNewsKeywordMutation, AddCrawlingNewsKeywordMutationVariables>(AddCrawlingNewsKeywordDocument, options);
      }
export type AddCrawlingNewsKeywordMutationHookResult = ReturnType<typeof useAddCrawlingNewsKeywordMutation>;
export type AddCrawlingNewsKeywordMutationResult = Apollo.MutationResult<AddCrawlingNewsKeywordMutation>;
export type AddCrawlingNewsKeywordMutationOptions = Apollo.BaseMutationOptions<AddCrawlingNewsKeywordMutation, AddCrawlingNewsKeywordMutationVariables>;
export const RemoveCrawlingNewsKeywordDocument = gql`
    mutation RemoveCrawlingNewsKeyword($keyword: String!) {
  removeCrawlingNewsKeyword(keyword: $keyword) {
    id
    keyword
  }
}
    `;
export type RemoveCrawlingNewsKeywordMutationFn = Apollo.MutationFunction<RemoveCrawlingNewsKeywordMutation, RemoveCrawlingNewsKeywordMutationVariables>;

/**
 * __useRemoveCrawlingNewsKeywordMutation__
 *
 * To run a mutation, you first call `useRemoveCrawlingNewsKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrawlingNewsKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrawlingNewsKeywordMutation, { data, loading, error }] = useRemoveCrawlingNewsKeywordMutation({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useRemoveCrawlingNewsKeywordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCrawlingNewsKeywordMutation, RemoveCrawlingNewsKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCrawlingNewsKeywordMutation, RemoveCrawlingNewsKeywordMutationVariables>(RemoveCrawlingNewsKeywordDocument, options);
      }
export type RemoveCrawlingNewsKeywordMutationHookResult = ReturnType<typeof useRemoveCrawlingNewsKeywordMutation>;
export type RemoveCrawlingNewsKeywordMutationResult = Apollo.MutationResult<RemoveCrawlingNewsKeywordMutation>;
export type RemoveCrawlingNewsKeywordMutationOptions = Apollo.BaseMutationOptions<RemoveCrawlingNewsKeywordMutation, RemoveCrawlingNewsKeywordMutationVariables>;
export const AssetNewsDocument = gql`
    query AssetNews($locale: String, $assetInfos: [AssetInfoInput!]!) {
  assetNews(locale: $locale, assetInfos: $assetInfos) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useAssetNewsQuery__
 *
 * To run a query within a React component, call `useAssetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetNewsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      assetInfos: // value for 'assetInfos'
 *   },
 * });
 */
export function useAssetNewsQuery(baseOptions: Apollo.QueryHookOptions<AssetNewsQuery, AssetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetNewsQuery, AssetNewsQueryVariables>(AssetNewsDocument, options);
      }
export function useAssetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetNewsQuery, AssetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetNewsQuery, AssetNewsQueryVariables>(AssetNewsDocument, options);
        }
export type AssetNewsQueryHookResult = ReturnType<typeof useAssetNewsQuery>;
export type AssetNewsLazyQueryHookResult = ReturnType<typeof useAssetNewsLazyQuery>;
export type AssetNewsQueryResult = Apollo.QueryResult<AssetNewsQuery, AssetNewsQueryVariables>;
export const BusinessNewsDocument = gql`
    query BusinessNews($country: String!, $limit: Int!, $page: Int) {
  businessNews(country: $country, limit: $limit, page: $page) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useBusinessNewsQuery__
 *
 * To run a query within a React component, call `useBusinessNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessNewsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBusinessNewsQuery(baseOptions: Apollo.QueryHookOptions<BusinessNewsQuery, BusinessNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessNewsQuery, BusinessNewsQueryVariables>(BusinessNewsDocument, options);
      }
export function useBusinessNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessNewsQuery, BusinessNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessNewsQuery, BusinessNewsQueryVariables>(BusinessNewsDocument, options);
        }
export type BusinessNewsQueryHookResult = ReturnType<typeof useBusinessNewsQuery>;
export type BusinessNewsLazyQueryHookResult = ReturnType<typeof useBusinessNewsLazyQuery>;
export type BusinessNewsQueryResult = Apollo.QueryResult<BusinessNewsQuery, BusinessNewsQueryVariables>;
export const HoldingAssetNewsDocument = gql`
    query HoldingAssetNews($country: String!, $limit: Int!, $page: Int) {
  holdingAssetNews(country: $country, limit: $limit, page: $page) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useHoldingAssetNewsQuery__
 *
 * To run a query within a React component, call `useHoldingAssetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingAssetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingAssetNewsQuery({
 *   variables: {
 *      country: // value for 'country'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useHoldingAssetNewsQuery(baseOptions: Apollo.QueryHookOptions<HoldingAssetNewsQuery, HoldingAssetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HoldingAssetNewsQuery, HoldingAssetNewsQueryVariables>(HoldingAssetNewsDocument, options);
      }
export function useHoldingAssetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HoldingAssetNewsQuery, HoldingAssetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HoldingAssetNewsQuery, HoldingAssetNewsQueryVariables>(HoldingAssetNewsDocument, options);
        }
export type HoldingAssetNewsQueryHookResult = ReturnType<typeof useHoldingAssetNewsQuery>;
export type HoldingAssetNewsLazyQueryHookResult = ReturnType<typeof useHoldingAssetNewsLazyQuery>;
export type HoldingAssetNewsQueryResult = Apollo.QueryResult<HoldingAssetNewsQuery, HoldingAssetNewsQueryVariables>;
export const SortedNewsDocument = gql`
    query SortedNews($sortType: sortType!, $language: String!, $limit: Int!, $page: Int, $country: String) {
  sortedNews(
    sortType: $sortType
    language: $language
    limit: $limit
    page: $page
    country: $country
  ) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useSortedNewsQuery__
 *
 * To run a query within a React component, call `useSortedNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSortedNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSortedNewsQuery({
 *   variables: {
 *      sortType: // value for 'sortType'
 *      language: // value for 'language'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSortedNewsQuery(baseOptions: Apollo.QueryHookOptions<SortedNewsQuery, SortedNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SortedNewsQuery, SortedNewsQueryVariables>(SortedNewsDocument, options);
      }
export function useSortedNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SortedNewsQuery, SortedNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SortedNewsQuery, SortedNewsQueryVariables>(SortedNewsDocument, options);
        }
export type SortedNewsQueryHookResult = ReturnType<typeof useSortedNewsQuery>;
export type SortedNewsLazyQueryHookResult = ReturnType<typeof useSortedNewsLazyQuery>;
export type SortedNewsQueryResult = Apollo.QueryResult<SortedNewsQuery, SortedNewsQueryVariables>;
export const SupportedCountryForNewsDocument = gql`
    query SupportedCountryForNews {
  supportedCountryForNews {
    ...NewsCountryFields
  }
}
    ${NewsCountryFieldsFragmentDoc}`;

/**
 * __useSupportedCountryForNewsQuery__
 *
 * To run a query within a React component, call `useSupportedCountryForNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedCountryForNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedCountryForNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportedCountryForNewsQuery(baseOptions?: Apollo.QueryHookOptions<SupportedCountryForNewsQuery, SupportedCountryForNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedCountryForNewsQuery, SupportedCountryForNewsQueryVariables>(SupportedCountryForNewsDocument, options);
      }
export function useSupportedCountryForNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedCountryForNewsQuery, SupportedCountryForNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedCountryForNewsQuery, SupportedCountryForNewsQueryVariables>(SupportedCountryForNewsDocument, options);
        }
export type SupportedCountryForNewsQueryHookResult = ReturnType<typeof useSupportedCountryForNewsQuery>;
export type SupportedCountryForNewsLazyQueryHookResult = ReturnType<typeof useSupportedCountryForNewsLazyQuery>;
export type SupportedCountryForNewsQueryResult = Apollo.QueryResult<SupportedCountryForNewsQuery, SupportedCountryForNewsQueryVariables>;
export const NewsDetailDocument = gql`
    query NewsDetail($newsId: ID!) {
  newsDetail(newsId: $newsId) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useNewsDetailQuery__
 *
 * To run a query within a React component, call `useNewsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsDetailQuery({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useNewsDetailQuery(baseOptions: Apollo.QueryHookOptions<NewsDetailQuery, NewsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsDetailQuery, NewsDetailQueryVariables>(NewsDetailDocument, options);
      }
export function useNewsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsDetailQuery, NewsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsDetailQuery, NewsDetailQueryVariables>(NewsDetailDocument, options);
        }
export type NewsDetailQueryHookResult = ReturnType<typeof useNewsDetailQuery>;
export type NewsDetailLazyQueryHookResult = ReturnType<typeof useNewsDetailLazyQuery>;
export type NewsDetailQueryResult = Apollo.QueryResult<NewsDetailQuery, NewsDetailQueryVariables>;
export const SearchNewsByKeywordDocument = gql`
    query SearchNewsByKeyword($language: String!, $platform: PlatformType!, $keyword: String!, $page: Int, $limit: Int!) {
  searchNewsByKeyword(
    language: $language
    platform: $platform
    keyword: $keyword
    page: $page
    limit: $limit
  ) {
    ...NewsFields
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useSearchNewsByKeywordQuery__
 *
 * To run a query within a React component, call `useSearchNewsByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNewsByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNewsByKeywordQuery({
 *   variables: {
 *      language: // value for 'language'
 *      platform: // value for 'platform'
 *      keyword: // value for 'keyword'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchNewsByKeywordQuery(baseOptions: Apollo.QueryHookOptions<SearchNewsByKeywordQuery, SearchNewsByKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNewsByKeywordQuery, SearchNewsByKeywordQueryVariables>(SearchNewsByKeywordDocument, options);
      }
export function useSearchNewsByKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNewsByKeywordQuery, SearchNewsByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNewsByKeywordQuery, SearchNewsByKeywordQueryVariables>(SearchNewsByKeywordDocument, options);
        }
export type SearchNewsByKeywordQueryHookResult = ReturnType<typeof useSearchNewsByKeywordQuery>;
export type SearchNewsByKeywordLazyQueryHookResult = ReturnType<typeof useSearchNewsByKeywordLazyQuery>;
export type SearchNewsByKeywordQueryResult = Apollo.QueryResult<SearchNewsByKeywordQuery, SearchNewsByKeywordQueryVariables>;
export const RecommendedSearchedNewsKeywordsDocument = gql`
    query RecommendedSearchedNewsKeywords($language: String!, $limit: Int!) {
  recommendedSearchedNewsKeywords(language: $language, limit: $limit) {
    id
    keyword
  }
}
    `;

/**
 * __useRecommendedSearchedNewsKeywordsQuery__
 *
 * To run a query within a React component, call `useRecommendedSearchedNewsKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedSearchedNewsKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedSearchedNewsKeywordsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecommendedSearchedNewsKeywordsQuery(baseOptions: Apollo.QueryHookOptions<RecommendedSearchedNewsKeywordsQuery, RecommendedSearchedNewsKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendedSearchedNewsKeywordsQuery, RecommendedSearchedNewsKeywordsQueryVariables>(RecommendedSearchedNewsKeywordsDocument, options);
      }
export function useRecommendedSearchedNewsKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendedSearchedNewsKeywordsQuery, RecommendedSearchedNewsKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendedSearchedNewsKeywordsQuery, RecommendedSearchedNewsKeywordsQueryVariables>(RecommendedSearchedNewsKeywordsDocument, options);
        }
export type RecommendedSearchedNewsKeywordsQueryHookResult = ReturnType<typeof useRecommendedSearchedNewsKeywordsQuery>;
export type RecommendedSearchedNewsKeywordsLazyQueryHookResult = ReturnType<typeof useRecommendedSearchedNewsKeywordsLazyQuery>;
export type RecommendedSearchedNewsKeywordsQueryResult = Apollo.QueryResult<RecommendedSearchedNewsKeywordsQuery, RecommendedSearchedNewsKeywordsQueryVariables>;
export const PopularSearchedNewsKeywordsDocument = gql`
    query PopularSearchedNewsKeywords($language: String!, $limit: Int!) {
  popularSearchedNewsKeywords(language: $language, limit: $limit) {
    ...PopularSearchedNewsKeywordFields
  }
}
    ${PopularSearchedNewsKeywordFieldsFragmentDoc}`;

/**
 * __usePopularSearchedNewsKeywordsQuery__
 *
 * To run a query within a React component, call `usePopularSearchedNewsKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularSearchedNewsKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularSearchedNewsKeywordsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePopularSearchedNewsKeywordsQuery(baseOptions: Apollo.QueryHookOptions<PopularSearchedNewsKeywordsQuery, PopularSearchedNewsKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopularSearchedNewsKeywordsQuery, PopularSearchedNewsKeywordsQueryVariables>(PopularSearchedNewsKeywordsDocument, options);
      }
export function usePopularSearchedNewsKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularSearchedNewsKeywordsQuery, PopularSearchedNewsKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopularSearchedNewsKeywordsQuery, PopularSearchedNewsKeywordsQueryVariables>(PopularSearchedNewsKeywordsDocument, options);
        }
export type PopularSearchedNewsKeywordsQueryHookResult = ReturnType<typeof usePopularSearchedNewsKeywordsQuery>;
export type PopularSearchedNewsKeywordsLazyQueryHookResult = ReturnType<typeof usePopularSearchedNewsKeywordsLazyQuery>;
export type PopularSearchedNewsKeywordsQueryResult = Apollo.QueryResult<PopularSearchedNewsKeywordsQuery, PopularSearchedNewsKeywordsQueryVariables>;
export const MyCrawlingNewsKeywordsDocument = gql`
    query MyCrawlingNewsKeywords {
  myCrawlingNewsKeywords {
    ...UserCrawlingNewsKeywordFields
  }
}
    ${UserCrawlingNewsKeywordFieldsFragmentDoc}`;

/**
 * __useMyCrawlingNewsKeywordsQuery__
 *
 * To run a query within a React component, call `useMyCrawlingNewsKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCrawlingNewsKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCrawlingNewsKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCrawlingNewsKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>(MyCrawlingNewsKeywordsDocument, options);
      }
export function useMyCrawlingNewsKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>(MyCrawlingNewsKeywordsDocument, options);
        }
export type MyCrawlingNewsKeywordsQueryHookResult = ReturnType<typeof useMyCrawlingNewsKeywordsQuery>;
export type MyCrawlingNewsKeywordsLazyQueryHookResult = ReturnType<typeof useMyCrawlingNewsKeywordsLazyQuery>;
export type MyCrawlingNewsKeywordsQueryResult = Apollo.QueryResult<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>;
export const CrawlingNewsKeywordNotificationStatusDocument = gql`
    query CrawlingNewsKeywordNotificationStatus($keyword: String!) {
  crawlingNewsKeywordNotificationStatus(keyword: $keyword)
}
    `;

/**
 * __useCrawlingNewsKeywordNotificationStatusQuery__
 *
 * To run a query within a React component, call `useCrawlingNewsKeywordNotificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrawlingNewsKeywordNotificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrawlingNewsKeywordNotificationStatusQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useCrawlingNewsKeywordNotificationStatusQuery(baseOptions: Apollo.QueryHookOptions<CrawlingNewsKeywordNotificationStatusQuery, CrawlingNewsKeywordNotificationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrawlingNewsKeywordNotificationStatusQuery, CrawlingNewsKeywordNotificationStatusQueryVariables>(CrawlingNewsKeywordNotificationStatusDocument, options);
      }
export function useCrawlingNewsKeywordNotificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrawlingNewsKeywordNotificationStatusQuery, CrawlingNewsKeywordNotificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrawlingNewsKeywordNotificationStatusQuery, CrawlingNewsKeywordNotificationStatusQueryVariables>(CrawlingNewsKeywordNotificationStatusDocument, options);
        }
export type CrawlingNewsKeywordNotificationStatusQueryHookResult = ReturnType<typeof useCrawlingNewsKeywordNotificationStatusQuery>;
export type CrawlingNewsKeywordNotificationStatusLazyQueryHookResult = ReturnType<typeof useCrawlingNewsKeywordNotificationStatusLazyQuery>;
export type CrawlingNewsKeywordNotificationStatusQueryResult = Apollo.QueryResult<CrawlingNewsKeywordNotificationStatusQuery, CrawlingNewsKeywordNotificationStatusQueryVariables>;
export const namedOperations = {
  Query: {
    AssetNews: 'AssetNews',
    BusinessNews: 'BusinessNews',
    HoldingAssetNews: 'HoldingAssetNews',
    SortedNews: 'SortedNews',
    SupportedCountryForNews: 'SupportedCountryForNews',
    NewsDetail: 'NewsDetail',
    SearchNewsByKeyword: 'SearchNewsByKeyword',
    RecommendedSearchedNewsKeywords: 'RecommendedSearchedNewsKeywords',
    PopularSearchedNewsKeywords: 'PopularSearchedNewsKeywords',
    MyCrawlingNewsKeywords: 'MyCrawlingNewsKeywords',
    CrawlingNewsKeywordNotificationStatus: 'CrawlingNewsKeywordNotificationStatus'
  },
  Mutation: {
    ToggleRecommend: 'ToggleRecommend',
    AddCrawlingNewsKeyword: 'AddCrawlingNewsKeyword',
    RemoveCrawlingNewsKeyword: 'RemoveCrawlingNewsKeyword'
  },
  Fragment: {
    NewsFields: 'NewsFields',
    NewsDetailFields: 'NewsDetailFields',
    NewsCountryFields: 'NewsCountryFields',
    PopularSearchedNewsKeywordFields: 'PopularSearchedNewsKeywordFields',
    UserCrawlingNewsKeywordFields: 'UserCrawlingNewsKeywordFields'
  }
}